import React, { useContext, useState, useEffect } from "react";
import { Card, Form, Table, Row, Col } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext";

const AccessoriesForm = () => {
  const {
    zipAcc,
    setZipAcc,
    adhesiveAcc,
    setAdhesiveAcc,
    zipLockAcc,
    setZipLockAcc,
    hookAcc,
    setHookAcc,
    handleAcc,
    setHandleAcc,

    responseDataOutput,
  } = useContext(productionSheetContext);

  const {
    zipAccOutput,
    adhesiveAccOutput,
    hookAccOutput,
    zipLockAccOutput,
    handleAccOutput,
  } = responseDataOutput || {};

  const handleChange = (setter) => (e) => {
    const { name, value } = e.target;
    setter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleZipAccChange = handleChange(setZipAcc);
  const handleAdhesiveAccChange = handleChange(setAdhesiveAcc);
  const handleZipLockAccChange = handleChange(setZipLockAcc);
  const handleHandleAccChange = handleChange(setHandleAcc);
  const handleHookAccChange = handleChange(setHookAcc);

  return (
    <>
      <Card className="mb-5">
        <Card.Header>
          <Card.Title>Accessories</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row className="mb-3">
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Zip ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="accItemId"
                  ì
                  value={zipAcc?.accItemId || ""}
                  onChange={handleZipAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={zipAcc?.description || ""}
                onChange={handleZipAccChange}
              />
            </Col>
            <Col xs={3}>
              <Form.Label>Zip weight (grams per meter)</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="unitWeightValue"
                value={zipAcc?.unitWeightValue || ""}
                onChange={handleZipAccChange}
              />
            </Col>
            <Col xs={2}>
              <Form.Label>Total meters</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                readOnly
                disabled
                value={zipAccOutput?.totalQuantityValue || ""}
              />
            </Col>
            <Col xs={1}>
              <Form.Label>Total weight</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                readOnly
                disabled
                value={zipAccOutput?.totalWeightValue || ""}
              />
            </Col>
          </Row>
          <hr />

          <Row className="mb-3">
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Adhesive ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="accItemId"
                  value={adhesiveAcc?.accItemId || ""}
                  onChange={handleAdhesiveAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={adhesiveAcc?.description || ""}
                onChange={handleAdhesiveAccChange}
              />
            </Col>
            <Col xs={3}>
              <Form.Group controlId="adhesiveAccId">
                <Form.Label>Adhesive weight (grams per meter)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="unitWeightValue"
                  value={adhesiveAcc?.unitWeightValue || ""}
                  onChange={handleAdhesiveAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>Total meters</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  readOnly
                  disabled
                  value={adhesiveAccOutput?.totalQuantityValue || ""}
                />
              </Form.Group>
            </Col>
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Total weight</Form.Label>
                <Form.Control
                  type="number"
                placeholder=""
                  readOnly
                  disabled
                  value={adhesiveAccOutput?.totalWeightValue || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />

          <Row className="mb-3">
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Zip Lock ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="accItemId"
                  value={zipLockAcc?.accItemId || ""}
                  onChange={handleZipLockAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={zipLockAcc?.description || ""}
                onChange={handleZipLockAccChange}
              />
            </Col>
            <Col xs={3}>
              <Form.Group controlId="zipLockAccId">
                <Form.Label>Zip lock weight (grams per meter)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="unitWeightValue"
                  value={zipLockAcc?.unitWeightValue || ""}
                  onChange={handleZipLockAccChange}
                />
                <Form.Label>Zip lock weight (grams each zip lock)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="gramsEachLockValue"
                  value={zipLockAcc?.gramsEachLockValue || ""}
                  onChange={handleZipLockAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>Total meters</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  readOnly
                  disabled
                  value={zipLockAccOutput?.totalQuantityValue || ""}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Total bags quantity</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Total weight</Form.Label>
                <Form.Control
                  name="zipLockAcc"
                  type="number"
                placeholder=""
                  readOnly
                  disabled
                  value={zipLockAccOutput?.totalWeightValue || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />

          <Row className="mb-3">
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Handle ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="accItemId"
                  value={handleAcc?.accItemId || ""}
                  onChange={handleHandleAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={handleAcc?.description || ""}
                onChange={handleHandleAccChange}
              />
            </Col>
            <Col xs={3}>
              <Form.Group controlId="handleAccId">
                <Form.Label>Handle weight (grams each)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="unitWeightValue"
                  value={handleAcc?.unitWeightValue || ""}
                  onChange={handleHandleAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>Total bags quantity</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  value={handleAccOutput?.totalQuantityValue || ""}
                  readOnly
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Total weight</Form.Label>
                <Form.Control
                  type="number"
                placeholder=""
                  readOnly
                  disabled
                  value={handleAccOutput?.totalWeightValue || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />

          <Row className="mb-3">
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Hook ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="accItemId"
                  value={hookAcc?.accItemId || ""}
                  onChange={handleHookAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="description"
                value={hookAcc?.description || ""}
                onChange={handleHookAccChange}
              />
            </Col>
            <Col xs={3}>
              <Form.Group controlId="hookAccId">
                <Form.Label>Hook weight (grams each)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  name="unitWeightValue"
                  value={hookAcc?.unitWeightValue || ""}
                  onChange={handleHookAccChange}
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>Total bags quantity</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  value={hookAccOutput?.totalQuantityValue || ""}
                  readOnly
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={1}>
              <Form.Group>
                <Form.Label>Total weight</Form.Label>
                <Form.Control
                  type="number"
                placeholder=""
                  readOnly
                  disabled
                  value={hookAccOutput?.totalWeightValue || ""}
                />
              </Form.Group>
            </Col>
          </Row>
          <hr />
        </Card.Body>
      </Card>
    </>
  );
};

export default AccessoriesForm;
