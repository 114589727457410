import React, { useContext, useEffect } from "react";
import productionSheetContext from "../../../store/productionSheetContext";
import { Container, Form, Row, Button, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const InformationForm = () => {
  const { informationInput, setInformationInput } = useContext(productionSheetContext);

  const {informationCustomer, informationItemCode, informationDescription} = informationInput || {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInformationInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
    

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          {" "}
          <Card.Title>Information</Card.Title>
        </Card.Header>

        <Card.Body>
          <Form.Group controlId="customer" className={"mb-2"}>
            <Form.Label> Customer</Form.Label>
            <Form.Control
              type="text"
              placeholder="Customer"
              name="informationCustomer"
              value={informationCustomer || ""}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="itemCode" className={"mb-2"}>
            <Form.Label>Item code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Item code"
              name="informationItemCode"
              value={informationItemCode}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="description" className={"mb-2"}>
            <Form.Label> Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Description"
              name="informationDescription"
              value={informationDescription || ""}
              onChange={handleChange}
            ></Form.Control>
          </Form.Group>
        </Card.Body>
      </Card>
    </>
  );
};

export default InformationForm;
