// Import react and router DOM
import React from "react";
import {Link} from "react-router-dom"

//Import components

//Import states, contexts and CSS
import { Container, Button } from "react-bootstrap";
import styles from "./notFoundPage.module.scss"



const NotFoundPage = () => {
  
  return (
    <Container className={styles["not-found-container"]}>
      <h1 className={styles["not-found-title"]}>404 - Page Not Found</h1>
      <p className={styles["not-found-text"]}>
        The page you are looking for might have been removed or is temporarily unavailable.
      </p>
<Button

as={Link}
to="/dashboard/productionSheetList">

Back to dashboard
</Button>
    </Container>
  );
};

export default NotFoundPage;
