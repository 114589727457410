const getMaterialOrMixture = async (materialOrMixtureId, userId, token) => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/materials/users/${userId}/materialOrMixture/${materialOrMixtureId}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const data = await response.json();
      return data; // Restituisce i dati senza modificarli
    } catch (error) {
      console.error("Error fetching materials:", error);
      return []; // Restituisce un array vuoto in caso di errore
    }
  };

  export default getMaterialOrMixture;