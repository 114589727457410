// Import react
import React, { useState, useEffect } from "react";

// Import states, contexts, and CSS
import { useActiveUser } from "../../store/activeUserContext";

import { SpinnerGeneric } from "../../utils/general/spinners";
import { Card, Form, Row, Col, Button, Table } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";

// Import components
import MaterialsModal from "./materialsModal";
import AddMaterialComponent from "./addMaterial";
import AddMixtureComponent from "./addMixture";

// Import utils and functions
import getMaterials from "../../utils/general/getMaterials";
import getMixtures from "../../utils/general/getMixtures";

const MaterialComponent = () => {
  // Get user data from context
  const { activeUser } = useActiveUser();

  // Get variables from local storage
  const userId = activeUser._id;
  const token = localStorage.getItem("token");

  // Variable for loading state
  const [isLoading, setIsLoading] = useState(false);

  // Variables for materials and mixtures
  const [materials, setMaterials] = useState([]);
  const [mixtures, setMixtures] = useState([]);
  const [update, setUpdate] = useState(false);

  // Variables for modal
  const [modalShow, setModalShow] = useState(false);
  const [modalElement, setModalElement] = useState(null);

  // Split material and mixture data function
  const separateMaterialsAndMixtures = (data) => {
    const materialsData = data.filter(
      (material) => material.type === "material"
    );
    const mixtureData = data.filter((material) => material.type === "mixture");
    return { materialsData, mixtureData };
  };

  // Update tables Materialcomponent function
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const materialsData = await getMaterials("", userId, token);

      // Updates local states
      setMaterials(materialsData);
      setIsLoading(false);
    };

    fetchData();
  }, [update]);

  // Update tables Mixtures function
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const mixturesData = await getMixtures("", userId, token);

      // Updates local states
      setMixtures(mixturesData);
      setIsLoading(false);
    };

    fetchData();
  }, [update]);

  // Handle open modal function
  const handleModal = (event) => {
    event.target.id === "add-material-modal"
      ? setModalElement("material")
      : setModalElement("mixture");
    setModalShow(true);
    console.log("Modal opened", event.target);
  };

  return (
    <div>
      {/* Utente registrato */}
      <Row>
        <Col xs={12} lg={12}>
          {activeUser ? (
            <>
              <div>Utente registrato: {activeUser._id}</div>
              <div>Nome utente: {activeUser.firstName}</div>
            </>
          ) : (
            <div>Nessun utente registrato.</div>
          )}
        </Col>
      </Row>

      {/* Form Nuovo Materiale */}
      <Row>
        <MaterialsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={modalElement === "material" ? "New material" : "New Mixture"}
          component={
            modalElement === "material" ? (
              <AddMaterialComponent update={update} setUpdate={setUpdate} />
            ) : (
              <AddMixtureComponent update={update} setUpdate={setUpdate} />
            )
          }
        />
      </Row>

      {/* Tabella Materiali */}
      <Row>
        <Col xs={12} lg={12}>
          <Button
            variant="warning"
            id="add-material-modal"
            onClick={handleModal}
          >
            <PlusCircle size={20} className="me-1" />
            New Material...
          </Button>
          <Card className="mt-4 mb-5">
            <Card.Header>
              <Card.Title>Materials Table</Card.Title>
            </Card.Header>
            <Card.Body>
              {isLoading ? (
                <>
                  <SpinnerGeneric />
                </>
              ) : (
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Material Id</th>
                      <th>Item Code</th>
                      <th>Name</th>
                      <th>Brand</th>
                      <th>Description</th>
                      <th>Specific Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {materials.map((material, index) => (
                      <tr key={index}>
                        <td>{material.materialId}</td>
                        <td>{material.itemCode}</td>
                        <td>{material.name}</td>
                        <td>{material.brand}</td>
                        <td>{material.description}</td>
                        <td>{material.specificWeight.toFixed(3)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Tabella Mixture */}
      <Row>
        <Col xs={12} lg={12}>
          <Button
            variant="warning"
            id="add-mixture-modal"
            onClick={handleModal}
          >
            <PlusCircle size={20} className="me-1" />
            New Mixture...
          </Button>
          <Card className="mt-4 mb-5">
            <Card.Header>
              <Card.Title>Mixtures Table</Card.Title>
            </Card.Header>
            <Card.Body>
              {isLoading ? (
                <>
                  <SpinnerGeneric />
                </>
              ) : (
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>mixtureId</th>
                      <th>Item Code</th>
                      <th>Name</th>
                      <th>Compositions</th>
                      <th>Ponderate Specific Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mixtures.map((mixture, index) => (
                      <tr key={index}>
                        <td>{mixture.mixtureId}</td>
                        <td>{mixture.itemCode}</td>
                        <td>{mixture.name}</td>{" "}
                        <td>
                          {mixture.composition.map((comp, i) => (
                            <div key={i}>
                              {comp.itemCode}: {comp.name} - {comp.percentage}%
                              (SW: {comp.specificWeight.toFixed(3)})
                            </div>
                          ))}
                        </td>
                        <td>
                        <td>{mixture.ponderatedSpecificWeight.toFixed(3)}</td>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MaterialComponent;
