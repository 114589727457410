// Import react
import React, { useState } from "react";

// Import states, contexts, and CSS
import { useActiveUser } from "../../store/activeUserContext";

import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { Save } from "react-bootstrap-icons";

// Import utils and functions
import getMaterials from "../../utils/general/getMaterials.jsx";

const AddMaterialComponent = ({ update, setUpdate }) => {
  // Get user data from context
  const { activeUser } = useActiveUser();

  // Get variables from local storage
  const userId = activeUser?._id || "";
  const token = localStorage.getItem("token") || "";

  // Variables for loading state
  const [isLoading, setIsLoading] = useState(false);
  const [materialsSending, setMaterialsSending] = useState(false);

  // Empty form for new material
  const emptyFormMaterial = {
    itemCode: "",
    description: "",
    category: "plastic",
    name: "",
    brand: "",
    type: "material",
    specificWeight: 0,
  };

  // Variables for new material
  const [newMaterial, setNewMaterial] = useState(emptyFormMaterial);

  // Function: handle input change for new material
  const handleSubmitMaterial = async (e) => {
    e.preventDefault();

    if (!userId) {
      alert("User ID is missing. Please log in again.");
      return;
    }

    if (
      !newMaterial.specificWeight ||
      newMaterial.specificWeight <= 0
    ) {
      alert("Invalid Ponderated Specific Weight");
      return;
    }

    setMaterialsSending(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/materials/users/${userId}/materials`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            itemCode: newMaterial.itemCode,
            description: newMaterial.description,
            category: newMaterial.category,
            name: newMaterial.name,
            brand: newMaterial.brand,
            type: newMaterial.type,
            specificWeight: newMaterial.specificWeight,
          }),
        }
      );

      // Check if the request was successful
      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(errorDetails.message || "Failed to create material");
      }
      const data = await response.json();
      alert("Material successfully added!");
      getMaterials("", userId, token);
    } catch (error) {
      console.error("Error adding material:", error);

      alert(`Error: ${error.message}`);
    } finally {
      setNewMaterial(emptyFormMaterial);
      setMaterialsSending(false);
      setIsLoading(false);
      setUpdate((prevUpdate) => !prevUpdate);
    }
  };

  // Function: handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewMaterial((prev) => {
      const updatedMaterial = { ...prev, [name]: value };
      return updatedMaterial;
    });
  };


  return (
    <>
      <Card className="mt-4 mb-5">
        <Card.Body>
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group controlId="newMaterialItemCode" className="mb-3">
                  <Form.Label>Item code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter item code"
                    name="itemCode"
                    value={newMaterial.itemCode}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="newMaterialName" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    value={newMaterial.name}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="newMaterialName" className="mb-3">
                  <Form.Label>Brand</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter brand"
                    name="brand"
                    value={newMaterial.brand}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="newMaterialDescription" className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter description"
                    name="description"
                    value={newMaterial.description}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group
                  controlId="newMaterialSpecificWeight"
                  className="mb-3"
                >
                  <Form.Label>Specific Weight</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter specific weight"
                    name="specificWeight"
                    value={newMaterial.specificWeight}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button
                variant="primary"
                name="addMaterial"
                onClick={handleSubmitMaterial}
                disabled={
                  !newMaterial.name ||
                  !newMaterial.brand ||
                  !newMaterial.specificWeight
                }
              >
                {materialsSending ? (
                  "Sending..."
                ) : (
                  <>
                    <Save size={20} className="me-1" />
                    Save Material
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddMaterialComponent;
