// Importa React e le librerie necessarie
import React, { useContext } from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";

const ProductionDetailsForm = () => {
  // Estrai gli stati e le funzioni di aggiornamento dal contesto
  const {
    quantityInput = { valueQuantityInput: "", unitQuantityInput: "1" },
    setQuantityInput,
    outputToleranceInput = { valueOutputToleranceInput: "", unitOutputToleranceInput: "1" },
    setOutputToleranceInput,
  } = useContext(productionSheetContext);

  // Gestore per le modifiche dei campi di quantità
  const handleChangeQuantity = (e) => {
    const { name, value } = e.target;
    setQuantityInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Gestore per le modifiche dei campi di tolleranza di output
  const handleChangeOutputTolerance = (e) => {
    const { name, value } = e.target;
    setOutputToleranceInput((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Card className="mb-5">
      <Card.Header>
        <Card.Title>Dettagli di Produzione</Card.Title>
      </Card.Header>
      <Card.Body>
        {/* Gruppo di input per la quantità */}
        <Form.Group controlId="quantity" className="mb-2 d-flex align-items-center">
          <Form.Label className="w-50">Quantity *</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              name="valueQuantityInput"
              value={quantityInput.valueQuantityInput}
              onChange={handleChangeQuantity}
            />
            <Form.Select
              name="unitQuantityInput"
              value={quantityInput.unitQuantityInput}
              onChange={handleChangeQuantity}
            >
              <option value="1">Pezzi</option>
              <option value="2">Kg</option>
            </Form.Select>
          </InputGroup>
        </Form.Group>

        {/* Gruppo di input per la tolleranza di output */}
        <Form.Group controlId="outputTolerance" className="mb-2 d-flex align-items-center">
          <Form.Label className="w-50">Tolleranza di Output</Form.Label>
          <InputGroup>
            <Form.Control
              type="number"
              name="valueOutputToleranceInput"
              value={outputToleranceInput.valueOutputToleranceInput}
              onChange={handleChangeOutputTolerance}
            />
            <Form.Select
              name="unitOutputToleranceInput"
              value={outputToleranceInput.unitOutputToleranceInput}
              onChange={handleChangeOutputTolerance}
            >
              <option value="1">%</option>
              <option value="2">Pezzi</option>
              <option value="3">Kg</option>
              <option value="4">Metri Lineari</option>
            </Form.Select>
          </InputGroup>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ProductionDetailsForm;

