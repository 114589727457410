import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Container,
  Card,
} from "react-bootstrap";
import { PlusCircle, XCircle } from "react-bootstrap-icons";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { SpinnerGeneric } from "../../../utils/general/spinners.jsx";
import Modal from "react-bootstrap/Modal";

// Import states, contexts, and CSS
import { useActiveUser } from "../../../store/activeUserContext.jsx";

import getMaterials from "../../../utils/general/getMaterials.jsx";
import getMixtures from "../../../utils/general/getMixtures.jsx";

function MaterialRowModal(props) {
  // Get user data from context
  const { activeUser } = useActiveUser();

  // Get variables from local storage
  const userId = activeUser._id;
  const token = localStorage.getItem("token");

  const { show, onHide } = props;
  // Variable for loading state
  const [isLoading, setIsLoading] = useState(false);
  const { materialsInput, setMaterialsInput } = useContext(productionSheetContext);

  // Estrai rowIndex dai props
  const { rowIndex = 0 } = props;

  const [materialsList, setMaterialsList] = useState([]);
  const [mixturesList, setMixturesList] = useState([]);

  const [materialFilter, setMaterialFilter] = useState("");
  const [mixtureFilter, setMixtureFilter] = useState("");

  // Definisci la funzione handleFilterChange
  const handleFilterChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleClickAdd = (e) => {
    const { value } = e.currentTarget;

    setMaterialsInput((prevMaterialsInput) => ({
      ...prevMaterialsInput,
      materials: prevMaterialsInput.materials.map((material, index) =>
        index === rowIndex
          ? {
              ...material,
              materialOrMixtureId: value,
            }
          : material
      ),
    }));

    props.onHide();
  };

  // Update tables Materialcomponent function
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const materialsData = await getMaterials(materialFilter, userId, token);
      setMaterialsList(materialsData);
      setIsLoading(false);
    };

    // Imposta un timer per il debounce
    const handler = setTimeout(() => {
      fetchData();
    }, 300); // Ritardo di 300 ms

    // Pulisce il timer se materialFilter cambia prima che il timer scada
    return () => {
      clearTimeout(handler);
    };
  }, [materialFilter, userId, token, show]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const mixturesData = await getMixtures(mixtureFilter, userId, token);
      setMixturesList(mixturesData);
      setIsLoading(false);
    };

  // Imposta un timer per il debounce
  const handler = setTimeout(() => {
    fetchData();
  }, 300); // Ritardo di 300 ms

    // Pulisce il timer se mixtureFilter cambia prima che il timer scada
    return () => {
      clearTimeout(handler);
    };
  }, [mixtureFilter, userId, token,show]);

  const resetFilters = () => {
    setMaterialFilter("");
    setMixtureFilter("");
  };
  const handleClose = () => {
    resetFilters();
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Material or Mixture
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col>
              <Card className="mt-4 mb-5">
                <Card.Header>
                  <Card.Title>
                    Materials Table
                    <Form.Control
                      name="materialFilter"
                      className="my-2"
                      type="text"
                      value={materialFilter}
                      onChange={handleFilterChange(setMaterialFilter)}
                    ></Form.Control>
                  </Card.Title>
                </Card.Header>

                <Card.Body>
                  {isLoading ? (
                    <>
                      <SpinnerGeneric />
                    </>
                  ) : (
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>Material Id</th>
                          <th>Material</th>

                          <th>Specific Weight</th>
                          <th>Add</th>
                        </tr>
                      </thead>
                      <tbody>
                        {materialsList.map((material, index) => (
                          <tr key={index}>
                            <td>{material.materialId}</td>
                            <td>
                              {material.itemCode} - {material.name} -{" "}
                              {material.brand} - {material.description}
                            </td>

                            <td>{material.specificWeight.toFixed(3)}</td>
                            <td>
                              <Button
                                variant="primary"
                                value={material.materialId}
                                onClick={handleClickAdd}
                              >
                                <PlusCircle />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Card.Body>
              </Card>
              <Card className="mt-4 mb-5">
                <Card.Header>
                  <Card.Title>
                    Mixtures Table
                    <Form.Control
                      name="mixtureFilter"
                      className="my-2"
                      type="text"
                      value={mixtureFilter}
                      onChange={handleFilterChange(setMixtureFilter)}
                    ></Form.Control>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {isLoading ? (
                    <>
                      <SpinnerGeneric />
                    </>
                  ) : (
                    <Table bordered hover>
                      <thead>
                        <tr>
                          <th>mixtureId</th>
                          <th>Mixture</th>
                          <th>Compositions</th>
                          <th>Ponderate Specific Weight</th>
                          <th> Add</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mixturesList.map((mixture, index) => (
                          <tr key={index}>
                            <td>{mixture.mixtureId}</td>
                            <td>
                              {mixture.itemCode} - {mixture.name}
                            </td>
                            <td>
                              {mixture.composition.map((comp, i) => (
                                <div key={i}>
                                  {comp.itemCode}: {comp.name} -{" "}
                                  {comp.percentage}% (SW:{" "}
                                  {comp.specificWeight.toFixed(3)})
                                </div>
                              ))}
                            </td>
                            <td>
                              <td>
                                {mixture.ponderatedSpecificWeight.toFixed(3)}
                              </td>
                            </td>
                            <td>
                              <td>
                                <Button
                                  variant="primary"
                                  value={mixture.mixtureId}
                                  onClick={handleClickAdd}
                                >
                                  <PlusCircle />
                                </Button>
                              </td>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MaterialRowModal;
