// Import react and router DOM
import React, { useState } from "react";
import { Link } from "react-router-dom";

//Import components

//Import states, contexts and CSS
import { Accordion, Card, Container, Row } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import ListGroup from "react-bootstrap/ListGroup";
import {
  PlusCircle,
  BoxArrowLeft,
  FilePerson,
  ListUl,
  ChevronBarRight,
  BagHeartFill,
  ListOl,
  FileText,
} from "react-bootstrap-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";

import { useActiveUser } from "../../store/activeUserContext";

import styles from "./dashboardNav.module.scss";

const DashboardNavbarMobile = () => {
  const { logout } = useActiveUser(); // Usa useActiveUser invece di useContext(ActiveUserContext)

  return (
    <Navbar
      fixed="bottom"
      bg="dark"
      data-bs-theme="dark"
      style={{
        paddingBottom: "10px",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 1000, // Imposta uno z-index alto, puoi regolarlo secondo le tue esigenze
      }}
    >
      <Container>
        <Row className="w-100">
          <Nav className="p-0 me-auto d-flex justify-content-center align-items-center">
            <Nav.Link as={Link} to="/dashboard/productionSheetPage">
              <PlusCircle size={20} className="mx-4" />
            </Nav.Link>
            <Nav.Link as={Link} to="/dashboard/productionSheetList">
              <ListUl size={20} className="mx-4" />
            </Nav.Link>

            <Nav.Link as={Link} to="/dashboard/materials">
              <FileText size={20} className="mx-4" />
            </Nav.Link>

            <Nav.Link as={Link} to="/dashboard/currentProductionList">
              <ListOl size={20} className="mx-4" />
            </Nav.Link>
            <Nav.Link as={Link} to="/profile">
              <FilePerson size={20} className="mx-4" />
            </Nav.Link>
            <Nav.Link to="/" onClick={logout}>
              <BoxArrowLeft size={20} className="mx-4" />
            </Nav.Link>
          </Nav>
        </Row>
      </Container>
    </Navbar>
  );
};

const DashboardNavbar = () => {
  const { logout } = useActiveUser();

  return (
    <div
      className={`${styles.sidebar}`}
      style={{
        width: "250px",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#f8f9fa",
        borderRight: "1px solid #ddd",
        overflowY: "auto",
        zIndex: 1000,
      }}
    >
      <div className="p-3">
        <div
          className="d-flex align-items-center mb-4"
          as={Link}
          to="/about"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <BagHeartFill size={30} className="me-2" />
          <h5 className="m-0">Smart Bag Pro</h5>
        </div>

        <ListGroup className="list-group-flush">
          <ListGroup.Item action as={Link} to="/dashboard/productionSheetPage">
            <PlusCircle size={20} className="me-2" />
            New Sheet
          </ListGroup.Item>
          <ListGroup.Item action as={Link} to="/dashboard/productionSheetList">
            <ListUl size={20} className="me-2" />
            Sheets List
          </ListGroup.Item>

          <ListGroup.Item action as={Link} to="/dashboard/materials">
            <FileText size={20} className="me-2" />
            Material List
          </ListGroup.Item>

          <ListGroup.Item
            action
            as={Link}
            to="/dashboard/currentProductionList"
            style={{ backgroundColor: "yellow" }}
          >
            <ListOl size={20} className="me-2" />
            Current production List
          </ListGroup.Item>

          <ListGroup.Item
            action
            as={Link}
            to="/dashboard/currentProductionList"
            style={{ backgroundColor: "yellow" }}
          >
            <ListOl size={20} className="me-2" />
            Item List
          </ListGroup.Item>

          <ListGroup.Item
            action
            as={Link}
            to="/dashboard/machineryList"
            style={{ backgroundColor: "yellow" }}
          >
            <ListOl size={20} className="me-2" />
            Machinery List
          </ListGroup.Item>


          <ListGroup.Item
            action
            as={Link}
            to="/dashboard/customerList"
            style={{ backgroundColor: "yellow" }}
          >
            <ListOl size={20} className="me-2" />
            Customer List
          </ListGroup.Item>
          <div className="my-5" />
          <ListGroup.Item action as={Link} to="/profile">
            <FilePerson size={20} className="me-2" />
            Personal info
          </ListGroup.Item>
          <ListGroup.Item action to="/" onClick={logout}>
            <BoxArrowLeft size={20} className="me-2" />
            Log out
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
};

export { DashboardNavbar, DashboardNavbarMobile };
