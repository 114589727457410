import React, { useContext, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { XCircle } from "react-bootstrap-icons";

const PackingDetailsFormRow = ({ pack, index }) => {
  const { packing, setPacking, responseDataOutput } = useContext(
    productionSheetContext
  );


  const handleChangeString = (e, index) => {
    const { name, value } = e.target;
  
    setPacking((prevPacking) => {
      const updatedPacking = [...prevPacking]; // Crea una copia dell'array
      updatedPacking[index] = { ...updatedPacking[index], [name]: String(value) }; // Modifica solo l'oggetto specifico
      return updatedPacking; // Ritorna il nuovo array aggiornato
    });
  };


  const handleChangeNumber = (e, index) => {
    const { name, value } = e.target;
  
    setPacking((prevPacking) => {
      const updatedPacking = [...prevPacking]; // Crea una copia dell'array
      updatedPacking[index] = { ...updatedPacking[index], [name]: Number(value) }; // Modifica solo l'oggetto specifico
      return updatedPacking; // Ritorna il nuovo array aggiornato
    });
  };
  


  const handleDeletePack = (index) => {
    setPacking((prevPacking) => prevPacking.filter((_, i) => i !== index));
  };
  
  const {packingOutput} = responseDataOutput || {};
  const packOutput = packingOutput?.[index] || "";
  const packQuantityValue = packOutput[0]?.packQuantityValue
  const packGrossWeightValue = packOutput[1]?.packGrossWeightValue
  
  return (
    <tr>
      <th>{index + 1}</th>
      <th>
        <InputGroup>
          <Form.Select name="packTypeValue" 
          
          
          onChange={(e) => handleChangeString(e, index)}
          value={pack.packTypeValue || "" }>
            <option value="1">pallet</option>
            <option value="2">carton box</option>
            <option value="3">pack</option>
            <option value="4">roll</option>
          </Form.Select>
        </InputGroup>
      </th>
      <th>
        <InputGroup>
          <Form.Select
            name="packQuantityUnitValue"
            value={pack.packQuantityUnitValue || ""}
            onChange={(e) => handleChangeString(e, index)}
          >
            <option value= "1" >kg</option>
            <option value= "2" >pcs</option>
          </Form.Select>
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            name="quantityEachPackValue"
            type="number"
            min="0"
            value={pack.quantityEachPackValue || ""}
            onChange={(e) => handleChangeNumber(e, index)}
          />
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            name="packTareWeightValue"
            type="number"
            min={0}
            value={pack.packTareWeightValue || ""}
            onChange={(e) => handleChangeNumber(e, index)}
          />
          <InputGroup.Text>Kg</InputGroup.Text>
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            type="number"
            readOnly
            disabled
            name="packGrossWeightValue"
            value={packGrossWeightValue?.toFixed(1) || ""}
          />
          <InputGroup.Text>Kg</InputGroup.Text>
        </InputGroup>
      </th>

      <th>
        <InputGroup>
          <Form.Control
            type="number"
            readOnly
            disabled
            name="packQuantityValue"
            value={packQuantityValue?.toFixed(0) || 0}
          />
        </InputGroup>
      </th>

      <th>
        <Button variant="danger" onClick={() => handleDeletePack(index)}>
          <XCircle />
        </Button>
      </th>
    </tr>
  );
};

export default PackingDetailsFormRow;
