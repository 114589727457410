// Import react
import React, { useState, useEffect } from "react";

// Import states, contexts, and CSS
import { useActiveUser } from "../../store/activeUserContext";

import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { PlusCircle, Save } from "react-bootstrap-icons";

// Import components
import NewMixtureRow from "./newMixtureRow";

// Import utils and functions
import calculatePonderatedSpecificWeight from "../../utils/calculateProductionDetails/calculatePonderateSpecificWeight";

const AddMixtureComponent = ({ update, setUpdate }) => {
  // Get user data from context
  const { activeUser } = useActiveUser();

  // Get variables from local storage
  const userId = activeUser?._id || "";
  const token = localStorage.getItem("token") || "";

  // Empty form data for new mixture
  const emptyFormMixture = {
    itemCode: "",
    name: "",
    description: "",
    category: "plastic",
    type: "mixture",
    composition: [{ material: {}, percentage: 0 }],
    ponderatedSpecificWeight: 0,
  };

  // Variables for new mixture
  const [newMixture, setNewMixture] = useState(emptyFormMixture);
  const [totalPercentageMixture, setTotalPercentageMixture] = useState(0);
  const [pondAverageSpecificWeight, setPondAverageSpecificWeight] = useState(0);

  // Variable for modal
  const [mixtureSending, setMixtureSending] = useState(false);

  // UseEffect for mixture sum percentage
  useEffect(() => {
    let sum = 0;
    newMixture.composition.forEach((material) => {
      sum += Number(material.percentage);
    });
    setTotalPercentageMixture(sum);
    setPondAverageSpecificWeight(calculatePonderatedSpecificWeight(newMixture));
    setNewMixture((prevMixture) => ({
      ...prevMixture,
      ponderatedSpecificWeight: calculatePonderatedSpecificWeight(newMixture),
    }));
  }, [newMixture.composition]);

  // Function: add new composition row
  const addCompositionRow = () => {
    setNewMixture((prevMixture) => ({
      ...prevMixture,
      composition: [
        ...prevMixture.composition,
        { material: {}, percentage: 0 }, // Make sure to include empty material object
      ],
    }));
  };

  // Function: handle input change for new mixture
  const handleInputChangeMixture = (e, rowIndex = null) => {
    const { name, value } = e.target;

    setNewMixture((prevMixture) => {
      const updatedMixture = { ...prevMixture };

      // If rowIndex is provided, update specific composition row
      if (rowIndex !== null) {
        const updatedComposition = [...updatedMixture.composition]; // Create a copy of the composition array
        updatedComposition[rowIndex] = {
          ...updatedComposition[rowIndex], // Copy specific row
          [name]: value, // Update only the specific field
        };

        // Update composition in the state
        updatedMixture.composition = updatedComposition;
      } else {
        // Otherwise, update other fields like name, itemCode, etc.
        updatedMixture[name] = value;
      }

      return updatedMixture; // Return the updated state
    });
  };

  // Function: separate materials and mixtures from data
  const separateMaterialsAndMixtures = (data) => {
    const materialsData = data.filter(
      (material) => material.type === "material"
    );
    const mixtureData = data.filter((material) => material.type === "mixture");
    return { materialsData, mixtureData };
  };

  const handleSubmitMixture = async (e) => {
    console.log("Submit mixture:", newMixture);
    console.log("Composition:", newMixture.composition);
    e.preventDefault();

    // Ensure the ponderatedSpecificWeight is valid
    if (newMixture.ponderatedSpecificWeight === 0) {
      alert("Invalid Ponderated Specific Weight");
      return;
    }

    setMixtureSending(true);

    try {
      // Send data to API (make sure the endpoint is correct)
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/materials/users/${userId}/mixtures`, // Correct endpoint for mixtures
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            category: newMixture.category,
            name: newMixture.name,
            description: newMixture.description,
            itemCode: newMixture.itemCode, // Send only the itemCode
            type: newMixture.type,
            composition: newMixture.composition.map(comp => ({
              _id: comp._id ,
              materialId: comp.materialId, // Send only the materialId
              percentage: comp.percentage,
            })),
            ponderatedSpecificWeight: newMixture.ponderatedSpecificWeight,
          }),
        }
      );

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(errorDetails.message || "Failed to create mixture");
      }
      const data = await response.json();
      alert("Mixture successfully added!");
    } catch (error) {
      console.error("Error adding mixture:", error);

      alert(`Error: ${error.message}`);
    } finally {
      setMixtureSending(false);
      setNewMixture(emptyFormMixture);
      setUpdate((prevUpdate) => !prevUpdate);
    }
  };

  useEffect(() => {
    
    console.log("New mixture:", newMixture);
  }, [newMixture]);

  return (
    <>
      <Card className="mt-4 mb-5">
        <Card.Body>
          <Form>
            <Row>
              <Form.Group controlId="newMixtureItemCode" className="mb-3">
                <Form.Label>Item code</Form.Label>
                <Form.Control
                  name="itemCode"
                  type="text"
                  placeholder="Enter item code"
                  onChange={(e) => handleInputChangeMixture(e)}
                  value={newMixture.itemCode}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="newMixtureDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  type="text"
                  placeholder="Enter description"
                  onChange={(e) => handleInputChangeMixture(e)}
                  value={newMixture.description}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="newMixtureName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Mixture name"
                  onChange={(e) => handleInputChangeMixture(e)}
                  value={newMixture.name}
                ></Form.Control>
              </Form.Group>
              <hr />

              <Form.Group controlId="newMixtureComposition" className="mb-3">
                <Row className="mb-2">
                  {newMixture.composition.map((compositionItem, index) => (
                    <NewMixtureRow
                      key={index}
                      rowIndex={index}
                      newMixture={newMixture}
                      setNewMixture={setNewMixture}
                      separateMaterialsAndMixtures={separateMaterialsAndMixtures}
                      update={update}
                    />
                  ))}
                </Row>
              </Form.Group>
            </Row>
            <hr />
            <Row>
              <Col xs={12} lg={7} />
              <Col xs={12} lg={2}>
                <Form.Control
                  name="ponderatedSpecificWeight"
                  type="number"
                  placeholder="Ponderated Specific Weight"
                  value={pondAverageSpecificWeight}
                  disabled
                  readOnly
                />
              </Col>
              <Col xs={12} lg={2}>
                <Form.Control
                  name="totalPercentage"
                  type="number"
                  placeholder="Total Percentage"
                  value={totalPercentageMixture}
                  disabled
                  readOnly
                />
              </Col>
              <Col xs={12} lg={1} />
            </Row>
            <div className="d-flex justify-content-end">
              <Button variant="success" onClick={addCompositionRow}>
                <PlusCircle size={20} />
              </Button>
            </div>

            <hr />
            <div className="d-flex justify-content-end">
            <Button
  name="addMixture"
  variant="primary"
  onClick={handleSubmitMixture}
  disabled={
    totalPercentageMixture !== 100 ||
    !newMixture.name ||
    newMixture.composition.length === 0 ||
    !newMixture.composition.every((comp) => comp.materialId)
  }
>

                {mixtureSending ? (
                  "Sending..."
                ) : (
                  <>
                    <Save size={20} className="me-1" />
                    Save Mixture
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddMixtureComponent;
