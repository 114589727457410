// Import react and router DOM
import React, { useContext, useEffect, useState } from "react";

//Import components

//Import states, contexts and CSS
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { SpinnerGeneric } from "../../../utils/general/spinners.jsx";
const PrintDetailsForm = () => {
  const { printing, setPrinting, responseDataOutput, loading } = useContext(
    productionSheetContext
  );

  const {
    colorsNumberBackValue,
    colorsNumberFrontValue,
    tracksNumberPrint,
    metersPerHour,
    printingSetupHours,
    printingSingleColorSetupHours,
  } = printing || {};

  const printingOutput = responseDataOutput?.printingOutput || {};

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrinting((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Print details</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="colorsNumberFront" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Front: Colors number</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Front colors number"
              name="colorsNumberFrontValue"
              value={colorsNumberFrontValue || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="colorsNumberBack" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Back: Colors number</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Back colors number"
              name="colorsNumberBackValue"
              value={colorsNumberBackValue || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="metersPerHour" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Meters per Hour</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Total meters to print"
              name="metersPerHour"
              value={metersPerHour || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="tracksNumberPrint" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Tracks Number - Print</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Total meters to print"
              name="tracksNumberPrint"
              value={tracksNumberPrint || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="printingSingleColorSetupHours"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>
              Setup printing machine - hours
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Printing setup hours"
              name="printingSetupHours"
              value={printingSetupHours || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>H</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="printingSingleColorSetupHours"
            className={"mb-2 d-flex"}
          >
            <Form.Label className={"w-50"}>Setup each color - hours</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Printing setup hours for each color"
              name="printingSingleColorSetupHours"
              value={printingSingleColorSetupHours || ""}
              onChange={handleChange}
            />
            <InputGroup.Text>H</InputGroup.Text>
          </InputGroup>

          <hr />
          <InputGroup controlId="colorsNumberTotal" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Total: Colors number</Form.Label>
            <Form.Control
              className="text-end"
              type="text"
              placeholder="Total colors number"
              name="colorsNumberTotalValue"
              value={
                loading
                  ? "Loading..."
                  : printingOutput?.colorsNumberTotalValue || ""
              }
              disabled
              readOnly
            />

            <InputGroup.Text>N</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="printingTypeFormal" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Printing colors</Form.Label>
            <Form.Control
              className="text-end"
              type="text"
              placeholder="..."
              name="printingTypeFormal"
              value={
                loading
                  ? "Loading..."
                  : printingOutput?.printingTypeFormal || ""
              }
              disabled
              readOnly
            />
            <InputGroup.Text>| | |</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="totalMetersToPrint" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Total meters to print</Form.Label>
            <Form.Control
              className="text-end"
              type="text"
              placeholder="Total meters to print"
              name="totalMetersToPrint"
              value={
                loading
                  ? "Loading..."
                  : printingOutput?.totalMetersToPrint?.toFixed(0) || 0
              }
              disabled
              readOnly
            />
            <InputGroup.Text>Mtl</InputGroup.Text>
          </InputGroup>

          <InputGroup controlId="hoursPrinting" className={"mb-2 d-flex"}>
            <Form.Label className={"w-50"}>Total hours of printing</Form.Label>
            {loading ? (
              <SpinnerGeneric />
            ) : (
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Total hours for printing"
                name="hoursPrinting"
                value={printingOutput?.hoursPrinting?.toFixed(1) || 0}
                disabled
                readOnly
              />
            )}
            <InputGroup.Text>H</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default PrintDetailsForm;
