// Import react and router DOM
import React, { useContext } from "react";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import { useActiveUser } from "../../../store/activeUserContext.jsx";
import { useTranslation } from "react-i18next";

const ReportForm = () => {
  const { activeUser } = useActiveUser();
  const { t } = useTranslation();
  const { responseDataOutput, loading } = useContext(productionSheetContext);

  return (
    <>
      <Card className="mb-5">
        <Card.Header style={{ backgroundColor: "#87CEEB", color: "black" }}>
          {" "}
          {/* Azzurro/Celeste */}
          <Card.Title>{t("report.extrusion")}</Card.Title>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>

      <Card className="mb-5">
        <Card.Header style={{ backgroundColor: "#28a745", color: "black" }}>
          {" "}
          {/* Verde */}
          <Card.Title>{t("report.print")}</Card.Title>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>

      <Card className="mb-5">
        <Card.Header style={{ backgroundColor: "#FFD700", color: "black" }}>
          {" "}
          {/* Giallo */}
          <Card.Title>{t("report.welding")}</Card.Title>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>

      <Card className="mb-5">
        <Card.Header style={{ backgroundColor: "#6c757d", color: "black" }}>
          {" "}
          {/* Grigio */}
          <Card.Title>{t("report.packaging")}</Card.Title>
        </Card.Header>
        <Card.Body></Card.Body>
      </Card>
    </>
  );
};

export default ReportForm;
