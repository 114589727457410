import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Button, Table } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import PackingDetailsFormRow from "./packingDetailsFormRow.jsx";

const PackingDetailsForm = () => {
  const { packing, setPacking, responseDataOutput } = useContext(
    productionSheetContext
  );


  
  // Funzione per aggiungere una nuova riga all'array packing
  const addRowPack = () => {
    setPacking((prevPacking) => [
      ...prevPacking,
      {
        packingId: "",
        packTypeValue: "",
        packQuantityUnitValue: "",
        quantityEachPackValue: 0,
        quantityEachPackUnit: "",
        packQuantityValue: 0,
        packQuantityUnit: "kg",
        packTareWeightValue: 0,
        packTareWeightUnit: "kg",
        packGrossWeightValue: 0,
        packGrossWeightUnit: "kg",
      },
    ]);
  };

  return (
    <>
      <Card className="mb-5">
        <Card.Header>
          <Card.Title>Packing details</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Index</th>
                <th>Pack type</th>
                <th>Unit</th>
                <th>Quantity per packing</th>
                <th>Tare weight</th>
                <th>Gross weight</th>
                <th>Quantity of Pack</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {packing.map((pack, index) => (
                <PackingDetailsFormRow key={index} index={index} pack={pack} />
              ))}
            </tbody>
          </Table>
          <hr />
          <Button variant="primary" onClick={addRowPack}>
            Add Pack
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default PackingDetailsForm;
