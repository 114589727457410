import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Gestione lingue
import i18n from "./i18n.js";
import { useTranslation } from "react-i18next";

// Import components
import NavBar from "./components/navbar/NavBar.jsx";
import LoginPage from "./views/loginPage/loginPage.jsx";
import HomePage from "./views/homepage/homepage.jsx";
import Footer from "./components/footer/Footer.jsx";
import NotFoundPage from "./views/notFoundPage/notFoundPage.jsx";
import CreateAccountPage from "./views/createAccountPage/createAccountPage.jsx";
import PrivateRoute from "./components/routesComponents/privateRoute.jsx";
import SoftwarePage from "./views/softwarePage/softwarePage.jsx";
import AboutPage from "./views/aboutPage/aboutPage.jsx";
import DashboardLayoutPage from "./views/dashboardPage/DashboardLayoutPage.jsx";

import ProfileData from "./components/profileData/profileData.jsx";
import ProductionSheetForm from "./components/productionSheetForm/ProductionSheetForm.jsx";
import ProductionSheetsTable from "./components/productionSheetsTable/productionSheetsTable.jsx";

import PrivateRouteLayout from "./components/routesComponents/privateRouteLayout.jsx";
import PublicRouteLayout from "./components/routesComponents/publicRouteLayout.jsx";

import MaterialComponent from "./components/materials/materials.jsx";

// Import states, contexts, and CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { ActiveUserProvider } from "./store/activeUserContext.jsx";

function App() {
  const [activeUser, setActiveUser] = useState();

  return (
    <>
      <BrowserRouter>
        <ActiveUserProvider value={{ activeUser, setActiveUser }}>
          <ToastContainer />
          <Routes>
            {/* Rotte pubbliche */}
            <Route
              path="/"
              element={
                <PublicRouteLayout>
                  <HomePage />
                </PublicRouteLayout>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRouteLayout>
                  <LoginPage />
                </PublicRouteLayout>
              }
            />
            <Route
              path="/createaccount"
              element={
                <PublicRouteLayout>
                  <CreateAccountPage />
                </PublicRouteLayout>
              }
            />
            <Route
              path="/software"
              element={
                <PublicRouteLayout>
                  <SoftwarePage />
                </PublicRouteLayout>
              }
            />
            <Route
              path="/about"
              element={
                <PublicRouteLayout>
                  <AboutPage />
                </PublicRouteLayout>
              }
            />
            <Route
              path="*"
              element={
                <PublicRouteLayout>
                  <NotFoundPage />
                </PublicRouteLayout>
              }
            />

            {/* Rotte private */}
            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="/profile"
                element={
                  <PrivateRouteLayout>
                    <DashboardLayoutPage
                      title={"Profile Details"}
                      children={<ProfileData />}
                    />
                  </PrivateRouteLayout>
                }
              />
              <Route
                path="/dashboard/productionSheetPage"
                element={
                  <PrivateRouteLayout>
                    <DashboardLayoutPage
                      title={"Production Sheet"}
                      children={<ProductionSheetForm />}
                    />
                  </PrivateRouteLayout>
                }
              />
              <Route
                path="/dashboard/productionSheetPage/:id"
                element={
                  <PrivateRouteLayout>
                    <DashboardLayoutPage
                      title={"Production Sheet"}
                      children={<ProductionSheetForm />}
                    />
                  </PrivateRouteLayout>
                }
              />
              <Route
                path="/dashboard/productionSheetList"
                element={
                  <PrivateRouteLayout>
                    <DashboardLayoutPage
                      title={"Production Sheet List"}
                      children={<ProductionSheetsTable />}
                    />
                  </PrivateRouteLayout>
                }
              />

              <Route
                path="/dashboard/materials"
                element={
                  <PrivateRouteLayout>
                    <DashboardLayoutPage
                      title={"Materials"}
                      children={<MaterialComponent />}
                    />
                  </PrivateRouteLayout>
                }
              ></Route>
              <Route
                path="/admin"
                element={
                  <PrivateRouteLayout>
                    <DashboardLayoutPage
                      title={"Admin"}
                      children={<ProfileData />}
                    />
                  </PrivateRouteLayout>
                }
              />
            </Route>
          </Routes>
        </ActiveUserProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
