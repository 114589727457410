// Calcolo dello specific weight ponderato
const calculatePonderatedSpecificWeight = (materiale) => {
    if (!materiale || !materiale.composition || materiale.composition.length === 0) {
      return 0;
    }
    let ponderatedSpecificWeight = 0;
  
    materiale.composition.forEach((element) => {
      ponderatedSpecificWeight += (Number(element.specificWeight) * Number(element.percentage)) / 100;
    });
  
    return Number(ponderatedSpecificWeight.toFixed(2)); // Restituisce il valore arrotondato a 2 decimali
  };

  export default calculatePonderatedSpecificWeight;