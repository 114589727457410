import React, { useContext, useEffect } from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import ExtrusionDetailsExtra from "./ExtrusionDetailsExtra.jsx";

const ExtrusionDetailsForm = () => {
  const { extrusionReels, setExtrusionReels, responseDataOutput } = useContext(
    productionSheetContext
  );

  const newExtrusionReels = responseDataOutput?.newExtrusionReels || {};

  

  const handleChangeNumberValue = (e) => {
    const { name, value } = e.target;
  
    setExtrusionReels((prevReels) => ({
      ...prevReels,
      [name]: Number(value),
    }));
  };
  


  const handleChangeStringValue = (e) => {
    const { name, value } = e.target;
  
    setExtrusionReels((prevReels) => ({
      ...prevReels,
      [name]: String(value),
      ...(value === "6" ? { tracksNumberValue: 2 } : {}),
    }));
    };
  
  
  return (
    <>
      <Card className="mb-5">
        <Card.Header>
          <Card.Title>Extrusion details</Card.Title>
        </Card.Header>

        
          <Card.Body>
            <Card.Title>Reel</Card.Title>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Extrusion type</Form.Label>
              <Form.Select
                name="reelTypeValue"
                value={extrusionReels?.reelTypeValue}
                onChange={handleChangeStringValue}
              >
                <option value="1">Tubular</option>
                <option value="2">Single Fold</option>
                <option value="3">Flat</option>
                <option value="4">Gusseted Tubular</option>
                <option value="5">Gusseted Single Fold</option>
                <option value="6">Tubular splitted into 2 single fold</option>
                <option value="7">
                  Tubular splitted into 2 flats together
                </option>
                <option value="8">
                  Tubular splitted into 2 flats separated
                </option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Tracks number</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder=""
                name="tracksNumberValue"
                value={extrusionReels?.tracksNumberValue || ""}
                onChange={handleChangeNumberValue}
                disabled={extrusionReels?.reelTypeValue === "6"}
                readOnly = {extrusionReels?.reelTypeValue === "6"}
              />
              <InputGroup.Text>N</InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-2 d-flex align-items-center">
              <Form.Label className="w-50">Tracks direction</Form.Label>
              <Form.Select
                name="tracksDirectionValue"
                value={extrusionReels?.tracksDirectionValue}
                onChange={handleChangeNumberValue}
              >
                <option value="1">Vertical</option>
                <option value="2">Horizontal</option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Right reel addition</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder=""
                name="rightReelAdditionValue"
                value={extrusionReels?.rightReelAdditionValue || ""}
                onChange={handleChangeNumberValue}
              />
              <InputGroup.Text>Cm</InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Left reel addition</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder=""
                name="leftReelAdditionValue"
                value={extrusionReels?.leftReelAdditionValue || ""}
                onChange={handleChangeNumberValue}
              />
              <InputGroup.Text>Cm</InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Reel band</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Reel band"
                name="reelBandValue"
                value={newExtrusionReels?.reelBandWithAdditionValue?.toFixed(1)|| 0}
                disabled
                readOnly
              />
              <InputGroup.Text>Cm</InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Meter/Weight Ratio</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Meter Weight Ratio"
                name="meterWeightRatioValue"
                value={
                  newExtrusionReels?.meterWeightRatioWithAdditionValue?.toFixed(1) || 0
                }
                disabled
                readOnly
              />
              <InputGroup.Text>Gr</InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Net Production Meters</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Net Production Meters"
                name="netProductionMetersValue"
                value={
                  newExtrusionReels?.netProductionMetersValue?.toFixed(
                    0
                  ) || 0
                }
                disabled
                readOnly
              />
              <InputGroup.Text>Mtl</InputGroup.Text>
            </InputGroup>

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Gross Production Meters</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Gross Production Meters"
                name="grossProductionMetersValue"
                value={
                  newExtrusionReels?.grossProductionMetersValue?.toFixed(0) || 0
                }
                disabled
                readOnly
              />
              <InputGroup.Text>Kg</InputGroup.Text>
            </InputGroup>

            <hr />

            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Net production Weight</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Gross Production Meters"
                name="netWeightExtrusion"
                value={
                  newExtrusionReels?.netWeightExtrusion?.toFixed(1) || 0
                }
                disabled
                readOnly
              />
              <InputGroup.Text>Kg</InputGroup.Text>
            </InputGroup>
            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Tolerance production Weight</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Gross Production Meters"
                name="toleranceWeightExtrusion"
                value={
                  newExtrusionReels?.toleranceWeightExtrusion?.toFixed(1) || 0
                }
                disabled
                readOnly
              />
              <InputGroup.Text>Kg</InputGroup.Text>
            </InputGroup>
            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Gross production Weight</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Gross Production Meters"
                name="grossWeightExtrusion"
                value={
                  newExtrusionReels?.grossWeightExtrusion?.toFixed(1) || 0
                }
                disabled
                readOnly
              />
              <InputGroup.Text>Mtl</InputGroup.Text>
            </InputGroup>
            <ExtrusionDetailsExtra/>
          </Card.Body>
      
      </Card>
    </>
  );
};

export default ExtrusionDetailsForm;
