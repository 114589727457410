// Import react and router DOM
import React, { useContext, useEffect } from "react";

//Import components

//Import states, contexts and CSS
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

// Gestione delle lingue
import { useTranslation } from "react-i18next";

const BagDetailsForm = () => {
  const { t } = useTranslation();


  const { bagDetailsInput, setBagDetailsInput, bagWeight } = useContext(
    productionSheetContext
  );
  const { responseDataOutput } = useContext(productionSheetContext) || {};

  const {
    bareWeight = 0,
    netDieCutWeight = 0,
    netDieCutWeightWithAccessoriesWeight = 0,
    totalThickness = 0,
    specificWeight = 0,
    piecesValueOutput = 0,
    meterWeightRatio = 0,
    meterWeightRatioWithAddition = 0,
    weightValueOutput = 0,
    accessoriesWeightTotal = 0,
    grossWeightWithAccessories = 0,
    packingTareWeightTotal = 0,
    totalWeightAccPlusPackinTare = 0,
  } = responseDataOutput || {};


  const { bareWeightValue, netDieCutWeightValueOutput } = bagWeight || "";
  const {
    bagTypeValue,
    widthValue,
    widthUnit,
    heightValue,
    heightUnit,
    openBottomGussetValue,
    openBottomGussetUnit,
    openSideGussetValue,
    openSideGussetUnit,
    zipFlapValue,
    zipFlapUnit,
    upperFlapOpenValue,
    upperFlapOpenUnit,
    upperBlockNotesValue,
    upperBlockNotesUnit,
    cuttingDieValue,
    cuttingDieUnit,
    thicknessValue,
    thicknessUnit,
    specificWeightValue,
    specificWeightUnit,
  } = bagDetailsInput || "";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBagDetailsInput((prevData) => ({
      ...prevData,
      [name]: Number(value),
    }));
  };



  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          {" "}
          <Card.Title>{t("bagDetails.title")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup controlId="bagType" className={"mb-2"}>
            <Form.Select
              aria-label="Select product type"
              name="bagType"
              value={bagTypeValue}
            >
              <option>Select product type</option>
              <option value="1">Polybag</option>
              <option value="2">Paper Bag</option>
              <option value="3">Zip bag</option>
            </Form.Select>
          </InputGroup>
          <InputGroup
            controlId="width"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.width")} *</Form.Label>
            <Form.Control
              className="text-end"
              required
              type="number"
              placeholder=""
              name="widthValue"
              value={widthValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="height"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.height")} *</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="heightValue"
              value={heightValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="bottomGussetOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.bottomGussetOpen")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="openBottomGussetValue"
              value={openBottomGussetValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="sideGussetOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.sideGussetOpen")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="openSideGussetValue"
              value={openSideGussetValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="upperFlapOpen"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.upperFlapOpen")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="upperFlapOpenValue"
              value={upperFlapOpenValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="zipFlap"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.zipFlap")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="zipFlapValue"
              value={zipFlapValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="zipFlap"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.upperBlockNotes")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="upperBlockNotesValue"
              value={upperBlockNotesValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text>Cm</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="cuttingDie"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.cuttingDie")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder=""
              name="cuttingDieValue"
              min={0}
              max={99}
              value={cuttingDieValue || ""}
              onChange={handleChange}
            ></Form.Control>
            <InputGroup.Text> % </InputGroup.Text>
          </InputGroup>
          <hr />
          <InputGroup
            controlId="bareWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>{t("bagDetails.productWeightEach")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product bareWeight"
              name="bareWeightValue"
              value={bareWeight.toFixed(2) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netDieCutWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
            {t("bagDetails.productWeightEachNetCuttingDie")}
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product weight net cutting die"
              name="netDieCutWeight"
              value={netDieCutWeight.toFixed(2) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <hr />
          <InputGroup
            controlId="thickness"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.thickness")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product thickness"
              name="thickness.value"
              value={totalThickness.toFixed(1) || 0}
              readOnly
              disabled
            ></Form.Control>
            <InputGroup.Text>My</InputGroup.Text>
          </InputGroup>

          <hr />

          <InputGroup
            controlId="materialSecificWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"w-50"}>{t("bagDetails.specificWeight")}</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Material specific weight"
              name="specificWeight.value"
              value={specificWeight.toFixed(3) || 0}
              readOnly
              disabled
            ></Form.Control>
            <InputGroup.Text>{"| | |"}</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default BagDetailsForm;
