import React, { useContext, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { XCircle, PlusCircle } from "react-bootstrap-icons";
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { useActiveUser } from "../../../store/activeUserContext.jsx";
import getMaterialOrMixture from "../../../utils/general/getMaterialOrMixture.jsx";
import MaterialRowModal from "./MaterialRowModal.jsx";
import { SpinnerGeneric } from "../../../utils/general/spinners.jsx";

const MaterialRowForm = ({ rowIndex, layer, materialId }) => {
  const { materialsInput,setMaterialsInput,unitMeasureInput } = useContext(productionSheetContext);
  const [modalShow, setModalShow] = useState(false);
  const { activeUser } = useActiveUser();
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const materialOrMixtureId =
      materialsInput.materials[rowIndex]?.materialOrMixtureId;

    const fetchData = async () => {
      if (materialOrMixtureId) {
        setIsLoading(true);
        setFetchError(null);
        try {
          const materialsData = await getMaterialOrMixture(
            materialOrMixtureId,
            activeUser._id,
            localStorage.getItem("token")
          );
          setSelectedMaterial(materialsData);
        } catch (error) {
          setFetchError("Errore nel caricamento del materiale.");
        } finally {
          setIsLoading(false);
        }
      } else {
        setSelectedMaterial(null);
      }
    };

    fetchData();
  }, [materialsInput.materials[rowIndex]?.materialOrMixtureId, activeUser._id]);

  const handleDeleteMaterial = () => {

    setMaterialsInput((prevMaterialsInput) => ({
     ...prevMaterialsInput,
      materials: prevMaterialsInput.materials.filter((_, index) => index!== rowIndex),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaterialsInput((prevMaterialsInput) => {
      const updatedMaterials = [...prevMaterialsInput.materials];
      updatedMaterials[rowIndex] = {
        ...updatedMaterials[rowIndex],
        [name]: value,
      };
      return {
        ...prevMaterialsInput,
        materials: updatedMaterials,
      };
    });
  };
  

   

  return (
    <tr>
      <th>
        <Form.Control type="text" readOnly disabled value={layer} />
      </th>
      <th>
        <Button variant="primary" onClick={() => setModalShow(true)}>
          <PlusCircle />
        </Button>
      </th>
      <th>
        <Form.Control type="text" readOnly disabled value={materialId} />
      </th>
      <th>
        {isLoading ? (
          <>
            <SpinnerGeneric />
          </>
        ) : fetchError ? (
          <div>{fetchError}</div>
        ) : selectedMaterial?.composition?.length > 0 ? (
          selectedMaterial.composition.map((comp, i) => (
            <div key={i}>
              {comp.itemCode}: {comp.name} - {comp.percentage}% (SW:{" "}
              {comp.specificWeight.toFixed(3)})
            </div>
          ))
        ) : (
          `${selectedMaterial?.name || ""} ${
            selectedMaterial?.name ? ": 100% (SW: " : ""
          } ${selectedMaterial?.specificWeight || ""}${
            selectedMaterial?.name ? ")" : ""
          } `
        )}
      </th>
      <th>
        <Form.Control
          type="number"
          disabled
          readOnly
          value={
            selectedMaterial?.ponderatedSpecificWeight?.toFixed(3) ??
            selectedMaterial?.specificWeight?.toFixed(3) ??
            0
          }
        />
      </th>
      
{  unitMeasureInput.thickness &&    <th>
        <Form.Control
          type="number"
          name="thickness"
          min={0.1}
          step={1}
          max={9999}
          value={materialsInput.materials[rowIndex]?.thickness || ""}
onChange={handleChange}        />
      </th>}

        {unitMeasureInput.bagWeight && 
      <th>
        <Form.Control
          type="number"
          name="percentage"
          min={0.1}
          step={1}
          max={100}
          value={materialsInput.materials[rowIndex]?.percentage || ""}
          onChange={handleChange}
        />
      </th>}
      <th>
        <Button variant="danger" 
          onClick={handleDeleteMaterial}        
        >
          <XCircle />
        </Button>
        <MaterialRowModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          rowIndex={rowIndex}
        />
      </th>
    </tr>
  );
};

export default MaterialRowForm;
