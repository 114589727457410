import React, { useContext } from "react";
import { Card, Form, InputGroup, Table } from "react-bootstrap";
import productionSheetContext from "../../../store/productionSheetContext.jsx";

const ExtrusionDetailsExtra = () => {
  const { responseDataOutput } = useContext(productionSheetContext);

  const newExtrusionReels = responseDataOutput?.newExtrusionReels || {};


  const { reelChildData, reelTypeValue } = newExtrusionReels;


  switch (reelTypeValue) {
    case "6":
      return (
        <>
          <hr />
          <h4 className="text-center">{reelChildData?.message || ""}</h4>{" "}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>First Fold</th>
                <th>Second Fold</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Reel Band</td>
                <td>{reelChildData?.reelBandValueChild.toFixed(1) || 0}</td>

                <td>{reelChildData?.reelBandValueChild.toFixed(1) || 0}</td>
              </tr>
              <tr>
                <td>Meter Weight Ratio</td>
                <td>{reelChildData?.meterWeightRatioChild.toFixed(1) || 0}</td>

                <td>{reelChildData?.meterWeightRatioChild.toFixed(1) || 0}</td>
              </tr>
              <tr>
                <td>Net production meters</td>
                <td>
                  {reelChildData?.netProductionMetersValue.toFixed(1) || 0}
                </td>

                <td>
                  {reelChildData?.netProductionMetersValue.toFixed(1) || 0}
                </td>
              </tr>
              <tr>
                <td>Gross production meters</td>
                <td>
                  {reelChildData?.grossProductionMetersValue.toFixed(1) || 0}
                </td>

                <td>
                  {reelChildData?.grossProductionMetersValue.toFixed(1) || 0}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      );

    case "7":
      return (
        <>
          <hr />
          <h4 className="text-center">{reelChildData?.message || ""}</h4>{" "}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>2 Flats together</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Reel Band</td>
                <td>{reelChildData?.reelBandValueChild.toFixed(1) || 0}</td>
              </tr>
              <tr>
                <td>Meter Weight Ratio</td>
                <td>{reelChildData?.meterWeightRatioChild.toFixed(1) || 0}</td>
              </tr>
              <tr>
                <td>Net production meters</td>
                <td>
                  {reelChildData?.netProductionMetersValue.toFixed(1) || 0}
                </td>
              </tr>
              <tr>
                <td>Gross production meters</td>
                <td>
                  {reelChildData?.grossProductionMetersValue.toFixed(1) || 0}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      );
    case "8":
      return (
        <>
          <hr />
          <h4 className="text-center">{reelChildData?.message || ""}</h4>{" "}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Data</th>
                <th>First Flat</th>
                <th>Second Flat</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Reel Band</td>
                <td>{reelChildData?.reelBandValueChild.toFixed(1) || 0}</td>

                <td>{reelChildData?.reelBandValueChild.toFixed(1) || 0}</td>
              </tr>
              <tr>
                <td>Meter Weight Ratio</td>
                <td>{reelChildData?.meterWeightRatioChild.toFixed(1) || 0}</td>

                <td>{reelChildData?.meterWeightRatioChild.toFixed(1) || 0}</td>
              </tr>
              <tr>
                <td>Net production meters</td>
                <td>
                  {reelChildData?.netProductionMetersValue.toFixed(1) || 0}
                </td>

                <td>
                  {reelChildData?.netProductionMetersValue.toFixed(1) || 0}
                </td>
              </tr>
              <tr>
                <td>Gross production meters</td>
                <td>
                  {reelChildData?.grossProductionMetersValue.toFixed(1) || 0}
                </td>

                <td>
                  {reelChildData?.grossProductionMetersValue.toFixed(1) || 0}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      );
    default:
      return; 
  }

  return (
    <>
      <hr />
      {reelChildData ? (
        <Card className="p-3">
          <Card.Title>Extrusion Reel Extra</Card.Title>
          <p>
            <strong>Message:</strong> {reelChildData.message}
          </p>
          <p>
            <strong>Child Reel Index:</strong> {reelChildData.childReelIndex}
          </p>
          <p>
            <strong>Meter Weight Ratio Child:</strong>{" "}
            {reelChildData.meterWeightRatioChild}
          </p>
          <p>
            <strong>Reel Band Value Child:</strong>{" "}
            {reelChildData.reelBandValueChild}
          </p>
          <p>
            <strong>Net Production Meters:</strong>{" "}
            {reelChildData.netProductionMetersValue}
          </p>
          <p>
            <strong>Gross Production Meters:</strong>{" "}
            {reelChildData.grossProductionMetersValue}
          </p>
        </Card>
      ) : (
        <p>No extra extrusion details available.</p>
      )}
    </>
  );
};

export default ExtrusionDetailsExtra;
