// Import react and router DOM
import React, { useState, useContext, useEffect } from "react";

//Import components

//Import utils and functions
import getDynamicValueCost from "../../../../utils/general/getDynamicValueCost";

//Import states, contexts and CSS
import Form from "react-bootstrap/Form";
import productionSheetContext from "../../../../store/productionSheetContext";
import styles from "./PriceTableRow.module.scss";
import Button from "react-bootstrap/Button";
import {
  DashCircle,
  ArrowDownCircle,
  ArrowUpCircle,
} from "react-bootstrap-icons";

import { SpinnerGeneric } from "../../../../utils/general/spinners";

const PriceTableRow = ({ index, costLabel }) => {
  const { costs, setCosts, responseDataOutput, loading } = useContext(
    productionSheetContext
  );

  const [customValueVar, setCustomValueVar] = useState(false);

  const { costsResultArray } = responseDataOutput?.costsResultArray || [];
  const { packingOutput } = responseDataOutput || {};

  console.log("packingOutput - row", packingOutput);

  const [valueOutputState, setValueOutputState] = useState(0);
  const [totalCostOutputState, setTotalCostOutputState] = useState(0);

  const { unit, costPrice, customValue, customValueBolean } =
    costs[index] || {};

  useEffect(() => {
    if (responseDataOutput?.costsResultArray) {
      const { valueOutput, totalCostOutput } =
        responseDataOutput.costsResultArray?.[index] || {};

      setValueOutputState(valueOutput || 0);
      setTotalCostOutputState(totalCostOutput || 0);
    }
  }, [responseDataOutput, index]); // Si aggiorna quando `responseDataOutput` cambia

  const handleDeleteCost = () => {
    let costsArray = [...costs];
    costsArray.splice(index, 1);
    setCosts(costsArray);
  };

  const handleMoveUp = () => {
    if (index === 0) return; // Non fare nulla se è il primo elemento
    let costsArray = [...costs];
    [costsArray[index], costsArray[index - 1]] = [
      costsArray[index - 1],
      costsArray[index],
    ]; // Swap
    setCosts(costsArray);
  };

  const handleMoveDown = () => {
    if (index === costs.length - 1) return; // Non fare nulla se è l'ultimo elemento
    let costsArray = [...costs];
    [costsArray[index], costsArray[index + 1]] = [
      costsArray[index + 1],
      costsArray[index],
    ]; // Swap
    setCosts(costsArray);
  };

  const handleBasedOn = (e) => {
    const { value } = e.target;

    // Prendi l'unità corrente dell'elemento
    const { unit } = costs[index] || {};

    // Aggiorna lo stato di customValueVar se il valore selezionato è "custom"
    if (value === "custom") {
      setCustomValueVar(true);
      console.log("Vero: ");
      if (costs[index]) {
        let updatedCosts = [...costs];
        updatedCosts[index] = {
          ...updatedCosts[index],
          unit: value,
          customValueBolean: true,
        };
        setCosts(updatedCosts);
      }
    } else {
      setCustomValueVar(false);
      // Se l'elemento esiste, aggiorna la sua unità
      if (costs[index]) {
        let updatedCosts = [...costs];
        updatedCosts[index] = {
          ...updatedCosts[index],
          customValue: 0,
          unit: value,
          customValueBolean: false,
        };
        setCosts(updatedCosts);
      }
    }
  };

  const handleCostPriceChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    let costsArray = [...costs];
    costsArray[index] = { ...costsArray[index], costPrice: value };
    setCosts(costsArray);
  };

  const handleCustomValueChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    let costsArray = [...costs];
    costsArray[index] = {
      ...costsArray[index],
      customValue: value,
    };
    setCosts(costsArray);
  };

  return (
    <>
      <tr className={styles.priceTableRow}>
        <td>{index + 1}</td>
        <td>
          <Form.Group>
            <Form.Control
              className="text-end"
              type="text"
              placeholder="cost"
              name="cost"
              step={0.01}
              value={costLabel}
              readOnly
              disabled
            />
          </Form.Group>
        </td>

        <td>
          <Form.Group>
            <Form.Select value={unit} onChange={handleBasedOn}>
              <option value="1">Select an Option</option>
              <option value="custom">Custom Value...</option>
              <option value="2">Net Production Weight</option>
              <option value="3">Gross Production Weight</option>
              <option value="4">Quantity (pcs)</option>
              <option value="5">No.Colors Front</option>
              <option value="6">No.Colors Back</option>
              <option value="7">No.Colors Total</option>
              <option value="8">Tolerance Weight</option>
              <option value="9">Net production meters</option>

              <option value="11">Gross production meters</option>
              <option value="14">Net Die Cut Weight</option>
              <option value="15">Zip meters</option>
              <option value="16">Adhesive meters</option>
              <option value="17">Zip lock meters</option>
              <option value="18">Handle pieces</option>
              <option value="19">Hook pieces</option>
              <option value="20">Hours printing - Total</option>
              <option value="21">Hours printing - Effective</option>
              <option value="22">Hours welding - Total</option>
              <option value="23">Hours printing - setup</option>
              <option value="24">Meters to print</option>
              {packingOutput?.map((pack, index) => {
                return (
                  <option key={packingOutput.id} value={packingOutput.id}>
                    {"N."}
                    {index + 1} - {"Packing"}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </td>
        <td>
          {customValueBolean ? (

loading ? (
<SpinnerGeneric/>

):(

  <Form.Group>
    <Form.Control
      className="text-end"
      name="customValueVar"
      type="number"
      placeholder="Custom Value"
      onChange={handleCustomValueChange}
      value={customValue || 0}
    />
  </Form.Group>

)
          ) : (loading ? (
            <SpinnerGeneric/>
            
            ):(
            
            <Form.Group>
              <Form.Control
                className="text-end"
                type="number"
                name="amount"
                placeholder="Value"
                value={valueOutputState?.toFixed(2) || 0}
                disabled
                readOnly
              />
            </Form.Group>)
          )}
        </td>

        <td>
          <Form.Group>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="cost"
              name="cost"
              step={0.01}
              onChange={handleCostPriceChange}
              value={costPrice}
            />
          </Form.Group>
        </td>
        <td>

          
          {
            loading ? (<SpinnerGeneric/> ) : (
              
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Total Cost"
                readOnly
                disabled
                value={totalCostOutputState?.toFixed(2) || 0}
              />
            )
          }
        </td>

        <td>
          <Button variant="danger" onClick={handleDeleteCost}>
            <DashCircle size={16} />
          </Button>
        </td>

        <td>
          <Button variant="warning" onClick={handleMoveUp}>
            <ArrowUpCircle size={16} />
          </Button>
        </td>
        <td>
          <Button variant="warning">
            <ArrowDownCircle size={16} onClick={handleMoveDown} />
          </Button>
        </td>
      </tr>
    </>
  );
};

export default PriceTableRow;
