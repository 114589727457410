import React, { useContext } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import productionSheetContext from "../../../store/productionSheetContext";

const WeldingDetailsForm = () => {
  const { weldingInput, setWeldingInput,responseDataOutput } = useContext(productionSheetContext);

  const {piecesPerHourOutput, hoursWelding} = responseDataOutput?.weldingOutput || {};
  // Funzione generica per aggiornare i valori numerici
  const handleChange = (e) => {
    const { name, value } = e.target;
    setWeldingInput((prev) => ({
      ...prev,
      [name]: parseFloat(value) || 0,
    }));
  };

  // Funzione per aggiornare il tipo di saldatura (pcsPerHour vs strokesPerHour)
  const handleChangeWeldingType = (e) => {
    const newWeldingType = e.target.value === "true"; // Converti stringa in booleano
  
    setWeldingInput((prev) => ({
      ...prev,
      usePcsPerHour: newWeldingType,
      weldingSetupHours: prev.weldingSetupHours, // Mantieni il valore attuale
      pcsPerHour: newWeldingType ? prev.pcsPerHour : 0,
      strokesPerHour: newWeldingType ? 0 : prev.strokesPerHour,
      numberOfTracksWelding:newWeldingType ? 0 : prev.numberOfTracksWelding,
    }));
  };
  

  return (
    <>
      <Card className="mb-5">
        <Card.Header>
          <Card.Title>Welding details</Card.Title>
        </Card.Header>

        <Card.Body>
          <InputGroup className="mb-2 d-flex">
            <Form.Label className="w-50">Welding setup hours</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Welding setup hours"
              name="weldingSetupHours"
              value={weldingInput?.weldingSetupHours?.toFixed(1) || 0}
              onChange={handleChange}
            />
            <InputGroup.Text>H</InputGroup.Text>
          </InputGroup>

          <hr />

          {/* Il valore predefinito è preso da weldingInput.usePcsPerHour */}
          <Form.Select
            name="usePcsPerHour"
            className="mb-2"
            value={weldingInput?.usePcsPerHour ? "true" : "false"}
            onChange={handleChangeWeldingType}
          >
            <option value="true">Pieces per hour</option>
            <option value="false">Strokes per hour</option>
          </Form.Select>

          {weldingInput?.usePcsPerHour ? (
            <InputGroup className="mb-2 d-flex">
              <Form.Label className="w-50">Pieces per hour</Form.Label>
              <Form.Control
                className="text-end"
                type="number"
                placeholder="Pieces per hour"
                name="pcsPerHour"
                value={weldingInput?.pcsPerHour?.toFixed(0) || 0}
                onChange={handleChange}
              />
              <InputGroup.Text>Pcs</InputGroup.Text>
            </InputGroup>
          ) : (
            <>
              <InputGroup className="mb-2 d-flex">
                <Form.Label className="w-50">Strokes per hour</Form.Label>
                <Form.Control
                  className="text-end"
                  type="number"
                  placeholder="Strokes per hour"
                  name="strokesPerHour"
                  value={weldingInput?.strokesPerHour?.toFixed(0) || 0}
                  onChange={handleChange}
                />
                <InputGroup.Text>N</InputGroup.Text>
              </InputGroup>

              <InputGroup className="mb-2 d-flex">
                <Form.Label className="w-50">Number of tracks</Form.Label>
                <Form.Control
                  className="text-end"
                  type="number"
                  placeholder="Number of tracks"
                  name="numberOfTracksWelding"
                  value={weldingInput?.numberOfTracksWelding?.toFixed(0) || 0}
                  onChange={handleChange}
                />
                <InputGroup.Text>N</InputGroup.Text>
              </InputGroup>
            </>
          )}

          <hr />

          <InputGroup className="mb-2 d-flex">
                <Form.Label className="w-50">Pieces per hour</Form.Label>
                <Form.Control
                  className="text-end"
                  type="number"
                  placeholder="Pieces per hour"
                  name="piecesPerHourOutput"
                  value={piecesPerHourOutput?.toFixed(0) || 0}
                  disabled
                  readOnly
                />
                <InputGroup.Text>Pcs</InputGroup.Text>
              </InputGroup>
              <InputGroup className="mb-2 d-flex">
                <Form.Label className="w-50">Total welding time</Form.Label>
                <Form.Control
                  className="text-end"
                  type="number"
                  placeholder="Pieces per hour"
                  name="hoursWelding"
                  value={hoursWelding?.toFixed(1) || 0}
                  disabled
                  readOnly
                />
                <InputGroup.Text>H</InputGroup.Text>
              </InputGroup>

        </Card.Body>
      </Card>
    </>
  );
};

export default WeldingDetailsForm;
