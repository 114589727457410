import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import productionSheetContext from "../../../../store/productionSheetContext";
import formatCurrency from "../../../../utils/general/formatCurrency";

const ValueTable = () => {
  const { responseDataOutput } = useContext(productionSheetContext);
  const revenueDataOutput = responseDataOutput?.revenueDataOutput || {};

  const {
    revenueTotal = 0,
    revenueEach = 0,
    revenueKg = 0,
    costsTotal = 0,
    costEach = 0,
    costKg = 0,
    profitTotal = 0,
    profitEach = 0,
    profitKg = 0,
    commissionTotal = 0,
    commissionEach = 0,
    commissionKg = 0,
  } = revenueDataOutput;

  const formatPercentage = (value) => {
    return isNaN(value) ? "0.0%" : `${value.toFixed(1)}%`;
  };

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th className="text-end">Total</th>
            <th className="text-end">Unit</th>
            <th className="text-end">Net Weight</th>
            <th className="text-end">Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Costs</td>
            <td className="text-end">{formatCurrency(costsTotal, 2, "€")}</td>
            <td className="text-end">{formatCurrency(costEach, 4, "€")}</td>
            <td className="text-end">{formatCurrency(costKg, 3, "€")}</td>
            <td className="text-end">
              {formatPercentage((costsTotal / revenueTotal) * 100)}
            </td>
          </tr>
          <tr>
            <td>Profit</td>
            <td className="text-end">{formatCurrency(profitTotal, 2, "€")}</td>
            <td className="text-end">{formatCurrency(profitEach, 4, "€")}</td>
            <td className="text-end">{formatCurrency(profitKg, 3, "€")}</td>
            <td className="text-end">
              {formatPercentage((profitTotal / revenueTotal) * 100)}
            </td>
          </tr>
          <tr>
            <td>Commissions</td>
            <td className="text-end">
              {formatCurrency(commissionTotal, 2, "€")}
            </td>
            <td className="text-end">
              {formatCurrency(commissionEach, 4, "€")}
            </td>
            <td className="text-end">
              {formatCurrency(commissionKg, 3, "€")}
            </td>
            <td className="text-end">
              {formatPercentage((commissionTotal / revenueTotal) * 100)}
            </td>
          </tr>
          <tr>
            <td>Total Revenue</td>
            <td className="text-end">{formatCurrency(revenueTotal, 2, "€")}</td>
            <td className="text-end">{formatCurrency(revenueEach, 4, "€")}</td>
            <td className="text-end">{formatCurrency(revenueKg, 3, "€")}</td>
            <td className="text-end">100.0%</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ValueTable;
