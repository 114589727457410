const formatCurrency = (value, decimals = 2, currency = "€") => {
  if (typeof value !== "number" || typeof decimals !== "number" || decimals < 0) {
    return "Invalid input";
  }

  // Formatta il numero con il numero di decimali scelto
  let formattedValue = value.toLocaleString("it-IT", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  // Restituisce il valore con la valuta specificata
  return `${currency} ${formattedValue}`;
};

export default formatCurrency;