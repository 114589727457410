// Import React and router DOM
import React, { useContext, useState, useEffect } from "react";

// Import components
import { Card, Form, Row, Col, Button, Table } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { PlusCircle } from "react-bootstrap-icons";

// Import states, contexts and utils
import productionSheetContext from "../../../store/productionSheetContext.jsx";
import { useActiveUser } from "../../../store/activeUserContext.jsx";
import getMaterials from "../../../utils/general/getMaterials.jsx";

import MaterialRowForm from "./MaterialRowForm.jsx";

const MaterialForm = () => {
  const {
    materialsInput,
    setMaterialsInput,
    unitMeasureInput,
    setUnitMeasureInput,
  } = useContext(productionSheetContext);
  const { activeUser } = useActiveUser();
  const userId = activeUser._id;
  const token = localStorage.getItem("token");

  const [materialsList, setMaterialsList] = useState([
    materialsInput.materials,
  ]);

  const handleAddMaterial = () => {
    setMaterialsInput((prevMaterialsInput) => {
      // Calcola il nuovo valore di layer
      const newLayer = prevMaterialsInput.materials.length + 1;

      // Crea un nuovo materiale con il layer calcolato
      const newMaterial = { layer: newLayer };

      // Restituisci lo stato aggiornato con il nuovo materiale aggiunto
      return {
        ...prevMaterialsInput,
        materials: [...prevMaterialsInput.materials, newMaterial],
      };
    });
  };

  const handleRadioChange = (e) => {
    const { name } = e.target;
    setUnitMeasureInput((prevState) => ({
      thickness: name === "thickness",
      bagWeight: name === "bagWeight",
      bagWeightGrams: name === "bagWeight" ? prevState.bagWeightGrams : "",
    }));
  };

  const handleBagWeightChange = (e) => {
    const { value } = e.target;
    setUnitMeasureInput((prevState) => ({
      ...prevState,
      bagWeightGrams: value,
    }));
  };

  return (
    <Card className="mb-5">
      <Card.Header>
        <Card.Title>Materials</Card.Title>
      </Card.Header>

      {materialsInput.materials && (
        <Card.Body>
          <Form>
            <Form.Label>Thickness *</Form.Label>
            <Form.Check
              type="radio"
              name="thickness"
              label="Microns"
              checked={unitMeasureInput.thickness || ""}
              onChange={handleRadioChange}
            />
            <Form.Check
              type="radio"
              name="bagWeight"
              label={"Bag Weight"}
              checked={unitMeasureInput.bagWeight || ""}
              onChange={handleRadioChange}
            />
            {unitMeasureInput.bagWeight && (
              <Form.Control
                type="text"
                name="bagWeightInput"
                value={unitMeasureInput.bagWeightGrams}
                onChange={handleBagWeightChange}
                placeholder="Enter bag weight in grams"
                className="mt-2"
              />
            )}
          </Form>

          <hr />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Layer #</th>
                <th>Add Material</th>
                <th>Id</th>
                <th>Composition</th>
                <th>Specific Weight</th>
                {unitMeasureInput.thickness && <th>Thickness (microns)</th>}
                {unitMeasureInput.bagWeight && <th>Percentage</th>}
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {materialsInput.materials.map((material, index) => (
                <MaterialRowForm
                  key={index}
                  rowIndex={index}
                  layer={material.layer}
                  materials={materialsList}
                  materialId={material.materialOrMixtureId || ""}
                />
              ))}

              
            </tbody>
          </Table>

          <div className="d-flex justify-content-end">
            <Button variant="success" onClick={handleAddMaterial}>
              Add layer
              <PlusCircle size={20}  />
            </Button>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default MaterialForm;
