import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import productionSheetContext from "../../../store/productionSheetContext";

const ResultsForm = () => {
  const { responseDataOutput } = useContext(productionSheetContext) || {};

  const {
    bareWeight = 0,
    netDieCutWeight = 0,
    netDieCutWeightWithAccessoriesWeight = 0,
    totalThickness = 0,
    specificWeight = 0,
    piecesValueOutput = 0,
    meterWeightRatio = 0,
    meterWeightRatioWithAddition = 0,
    weightValueOutput = 0,
    accessoriesWeightTotal = 0,
    grossWeightWithAccessories = 0,
    packingTareWeightTotal = 0,
    totalWeightAccPlusPackinTare = 0,
    bareWeightTotal = 0,
    netDieCutWeightTotal = 0,
  } = responseDataOutput || {};
  
  return (
    <>
      <Card className={"mb-5"}>
        <Card.Header>
          <Card.Title>Results</Card.Title>
        </Card.Header>
        <Card.Body>
          <InputGroup
            controlId="bareWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Product weight each</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product bareWeight"
              name="bareWeight"
              value={bareWeight?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netDieCutWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Product weight each - cutting die
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product weight net cutting die plus accessories"
              name="netDieCutWeight"
              value={netDieCutWeight?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>
          <InputGroup
            controlId="netDieCutWeightWithAccessoriesWeight"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Product weight each - cutting die + accessories
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Product weight net cutting die plus accessories"
              name="netDieCutWeightWithAccessoriesWeight"
              value={netDieCutWeightWithAccessoriesWeight?.toFixed(1)}
              disabled
              readOnly
            />
            <InputGroup.Text>Gr</InputGroup.Text>
          </InputGroup>
          <hr />
          <InputGroup
            controlId="quantity"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Quantity (pcs)</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Quantity"
              name="quantity"
              value={piecesValueOutput?.toFixed(0) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Pcs</InputGroup.Text>
          </InputGroup>

          <hr />
          <InputGroup
            controlId="bareWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Bare weight total
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Bare production weight"
              name="bareWeightTotal"
              value={bareWeightTotal?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="netDieCutWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net Cut Die weight total
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Net die cut weight total "
              name="netDieCutWeightTotal"
              value={netDieCutWeightTotal?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>


          <InputGroup
            controlId="weightValueOutput"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net production weight
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Gross production weight"
              name="weightValueOutput"
              value={weightValueOutput?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="accessoriesWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Accessories weight total{" "}
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Net Die Cut weight total"
              name="accessoriesWeightTotal"
              value={accessoriesWeightTotal?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="grossWeightWithAccessories"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net production weight + accessories{" "}
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Net die cut weight + tare weight total"
              name="grossWeightWithAccessories"
              value={grossWeightWithAccessories?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>

          <InputGroup
            controlId="packingTareWeightTotal"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>Tare weight total</Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Tare weight total"
              name="packingTareWeightTotal"
              value={packingTareWeightTotal?.toFixed(1)}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>



          <InputGroup
            controlId="totalWeightAccPlusPackinTare"
            className={"mb-2 d-flex align-items-center"}
          >
            <Form.Label className={"me-3 w-50"}>
              Net Die Cut Weight Total + Tare weight total + Accessories
            </Form.Label>
            <Form.Control
              className="text-end"
              type="number"
              placeholder="Net die cut weight + tare weight total"
              name="totalWeightAccPlusPackinTare"
              value={totalWeightAccPlusPackinTare?.toFixed(1) || 0}
              disabled
              readOnly
            />
            <InputGroup.Text>Kg</InputGroup.Text>
          </InputGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default ResultsForm;
