// Import react and router DOM


const emptyCreateAccountForm = {
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  countryOfResidence: null,
  email: "",
  password: "",
};

export {emptyCreateAccountForm };
