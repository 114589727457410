// Import react
import React, { useState, useEffect } from "react";
import { useActiveUser } from "../../store/activeUserContext";
import { Row, Col, Form, Button } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";
import getMaterials from "../../utils/general/getMaterials";

const NewMixtureRow = ({
  rowIndex,
  setNewMixture,
  newMixture,
  separateMaterialsAndMixtures,
  update,
}) => {
  const { activeUser } = useActiveUser();
  const userId = activeUser._id;
  const token = localStorage.getItem("token");

  const [materialList, setMaterialList] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle changes on input fields
  const handleInputChangeMixture = (e, rowIndex) => {
    const { name, value } = e.target;

    setNewMixture((prevMixture) => {
      const updatedComposition = prevMixture.composition.map((comp, index) =>
        index === rowIndex
          ? { ...comp, [name]: name === "percentage" ? Number(value) : value }
          : comp
      );

      return { ...prevMixture, composition: updatedComposition };
    });
  };

  // Fetch materials
  useEffect(() => {
    setIsLoading(true);
    const fetchMaterials = async () => {
      try {
        const data = await getMaterials(filter, userId, token);
        const { materialsData } = separateMaterialsAndMixtures(data);
        setMaterialList(materialsData);
      } catch (error) {
        console.error("Error fetching materials:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const timer = setTimeout(fetchMaterials, 500);
    return () => clearTimeout(timer);
  }, [filter, update]);

  // Delete row
  const handleRemoveRow = (indexToRemove) => {
    setNewMixture((prevMixture) => ({
      ...prevMixture,
      composition: prevMixture.composition.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  // Select material
  const handleSelectMaterial = (e, rowIndex) => {
    const { value } = e.target;
    const selectedMaterial = materialList.find((mat) => mat.materialId === value);

    const selectedMaterial_Id = selectedMaterial._id;
    const selectMaterialMaterialId = selectedMaterial.materialId;

    console.log("handleSelectMaterial", selectedMaterial);
    console.log("handleSelectMaterial", value);
    
    if (selectedMaterial) {
      setNewMixture((prevMixture) => {
        const updatedComposition = [...prevMixture.composition];
        updatedComposition[rowIndex] = {
          _id: selectedMaterial_Id,
          materialId: selectMaterialMaterialId,
          percentage: updatedComposition[rowIndex]?.percentage || 0,
        };
        return { ...prevMixture, composition: updatedComposition };
      });
    }

    setFilter("");
  };

  useEffect(() => {
    console.log("newMixture.composition", newMixture.composition);
  }, [newMixture]);

  return (
    <div>
      <hr />
      <Row className="mb-2">
        <Col xs={12} lg={3}>
          {rowIndex === 0 && <Form.Label>Filter</Form.Label>}
          <Form.Control
            name="filter"
            type="text"
            placeholder="Filter material"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Col>

        <Col xs={12} lg={4}>
          {rowIndex === 0 && <Form.Label>Material</Form.Label>}
          <Form.Select
            name="material"
            value={newMixture.composition[rowIndex]?.materialId || ""}
            onChange={(e) => handleSelectMaterial(e, rowIndex)}
            disabled={isLoading}
          >
            {isLoading ? (
              <option disabled>Loading materials...</option>
            ) : materialList.length === 0 ? (
              <option value="" disabled>
                No materials found
              </option>
            ) : (
              <>
                <option value="" disabled>
                  Select a material
                </option>
                {materialList.map((material) => (
                  <option key={material.materialId} value={material.materialId}>
                    {`${material.name} - ${
                      material.brand || "No Brand"
                    } - Specific Weight: ${material.specificWeight || "N/A"}`}
                  </option>
                ))}
              </>
            )}
          </Form.Select>
        </Col>

        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Specific weight</Form.Label>}
          <Form.Control
            type="number"
            name="specificWeight"
            disabled
            readOnly
            value={
              newMixture.composition[rowIndex]?.specificWeight || ""
            }
          />
        </Col>

        <Col xs={12} lg={2}>
          {rowIndex === 0 && <Form.Label>Percentage</Form.Label>}
          <Form.Control
            type="number"
            name="percentage"
            min={0.1}
            max={99.9}
            value={newMixture.composition[rowIndex]?.percentage || 0}
            onChange={(e) => handleInputChangeMixture(e, rowIndex)}
          />
        </Col>

        <Col xs={12} lg={1}>
          {rowIndex === 0 && <Form.Label>Delete</Form.Label>}
          <Button variant="danger" onClick={() => handleRemoveRow(rowIndex)}>
            <XCircle />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewMixtureRow;
