import React, { useState, useContext, useEffect } from "react";
import PriceTableRow from "./priceTableRow/PriceTableRow.jsx";
import ValueTable from "./valueTable/valueTable.jsx";
import Form from "react-bootstrap/Form";
import { Button, Table } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import productionSheetContext from "../../../store/productionSheetContext";
import Card from "react-bootstrap/Card";

const PriceTable = () => {
  const { costs, setCosts, profit, setProfit, currency } = useContext(productionSheetContext);
  const [newCostLabel, setNewCostLabel] = useState("");

  const addCost = () => {
    const newCost = {
      label: newCostLabel || "New Cost",
      costPrice: 1000,
      unit: "5",
    };
    setCosts((prevData) => [...prevData, newCost]);
    setNewCostLabel("");
  };

  useEffect(() => {
    console.log("Costi aggiornati: ", costs);
  }, [costs]);

  const { profitUnit, profitAmount, commissionUnit, commissionAmount } = profit || {};

  const handleAmount = (e) => {
    const { name, value } = e.target;
    setProfit((prev) => ({
      ...prev,
      [name]: isNaN(parseFloat(value)) ? 0 : parseFloat(value),
    }));
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    setProfit((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {/* Tabella Costi */}
      <Card className={"mb-5"}>
      <Card.Header>
      <Card.Title>Costs</Card.Title>
      </Card.Header>
      <Card.Body>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Cost</th>
            <th>Based on</th>
            <th>Value</th>
            <th>Cost</th>
            <th>Total Cost</th>
          </tr>
        </thead>
        <tbody>
          {costs.map((cost, index) => (
            <PriceTableRow
              costLabel={cost.label}
              costName={cost.cost_id}
              key={cost.cost_id || index} // Usa index come fallback per evitare errori
              index={index}
              currency={currency}
              setCosts={setCosts}
              profit={profit}
            />
          ))}
          <tr>
            <td colSpan={7}>
              <Form.Control
                type="text"
                placeholder="Enter cost"
                value={newCostLabel}
                onChange={(e) => setNewCostLabel(e.target.value)}
              />
            </td>
            <td colSpan={3}>
              <Button variant="success" onClick={addCost}>
                <PlusCircle size={20} /> Add Cost
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      </Card.Body>
      </Card>

      <Card className={"mb-5"}>
      <Card.Header>
      <Card.Title>Profit and commissions</Card.Title>
      </Card.Header>
      <Card.Body>
      {/* Tabella Profitto e Commissioni */}
      <Table bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Unit</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {/* RIGA PROFITTO */}
          <tr>
            <td>Profit</td>
            <td>
              <Form.Select name="profitUnit" value={profitUnit} onChange={handleSelect}>
                <option value="1">Select</option>
                <option value="2">Profit Amount</option>
                <option value="3">% Markup</option>
                <option value="4">% Margin</option>
              </Form.Select>
            </td>
            <td>
              <Form.Control
                type="number"
                name="profitAmount"
                placeholder="Profit amount"
                value={profitAmount}
                onChange={handleAmount}
              />
            </td>
          </tr>

          {/* RIGA COMMISSIONI */}
          <tr>
            <td>Commissions</td>
            <td>
              <Form.Select name="commissionUnit" value={commissionUnit} onChange={handleSelect}>
                <option value="1">Select</option>
                <option value="2">Commission Amount</option>
                <option value="3">% Commission</option>
              </Form.Select>
            </td>
            <td>
              <Form.Control
                type="number"
                name="commissionAmount"
                placeholder="Commission Amount"
                value={commissionAmount}
                onChange={handleAmount}
              />
            </td>
          </tr>
        </tbody>
      </Table>

      </Card.Body>
      </Card>
      <Card className={"mb-5"}>
      <Card.Header>
      <Card.Title>Costs Table</Card.Title>
      </Card.Header>
      <Card.Body>
      {/* Tabella Valori Finali */}
      <ValueTable />
      </Card.Body>
      </Card>
    </>
  );
};

export default PriceTable;

