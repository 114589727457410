// Import react and router DOM
import React from "react";
import { useParams } from "react-router-dom";

// Import components
import {
  DashboardNavbar,
  DashboardNavbarMobile,
} from "../../components/dashboardNav/dashboardNavbar.jsx";

// Import states, contexts and CSS
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const DashboardLayoutPage = ({ title, children }) => {

  // Ottieni l'id dai parametri dell'URL
  const { id } = useParams(); 

  // Clona il children e gli passa l'id come prop
  const clonedChildren = React.cloneElement(children, { productionSheetId: id });

  return (
    <>
      <Row className="my-4 mx-3">
        <Col xxl={2} className="d-none d-xxl-block">
          <DashboardNavbar />
        </Col>
        <Col xs={12} xxl={10}>
          <Card className="mb-2" border="dark">
            <Card.Header>
              <h1 className={" text-center"}>{title}</h1>
            </Card.Header>
          </Card>
          {clonedChildren}
        </Col>
        <Col xxl={0}></Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} className="d-xxl-none">
          <DashboardNavbarMobile />
        </Col>
      </Row>
    </>
  );
};

export default DashboardLayoutPage;
